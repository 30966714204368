/**
 * Script to show the appropriate collateral download
 * to the user on the thank-you page based on their
 * referring URL.
 */
import collateral from './../../_data/collateral';

function showCollateral() {
    const collateralContainer = document.querySelector('#collateral-download');
    const collateralTemplate = document.querySelector('#collateral-download-template');

    // Derive collateral ID from the referrer URL
    // ie. asdf.edu/collateral/[abc]/
    let referrerUrl = document.referrer.match(/\/collateral\/([^\/?]+)\/?[^\/]*?$/);

    if (collateralContainer && collateralTemplate) {
        if (referrerUrl != null) {
            const collateralId = referrerUrl[1];
            //const collateralId = 'mba'; //for testing
            let collateralContent = collateralTemplate.content.cloneNode(true);

            Object.keys(collateral[collateralId]).forEach((key) => {
                collateralContent.childNodes.forEach((node) => {
                    if (typeof node.innerHTML != "undefined") {
                        node.innerHTML = node.innerHTML.replace("[" + key + "]", collateral[collateralId][key]);
                    }
                })
            })

            collateralContainer.appendChild(collateralContent);
            
        }
    }
}

export default showCollateral;